import React from "react";
import ThemeToggler from "components/common/ThemeToggler";
import { useTranslation } from "react-i18next";

const ThemePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="theme-page ">
      <div className="theme-header">
        <h1>{t("chooseTheme")}</h1>
        <p>
          {t("themeDescription") ||
            "Customize your experience by selecting a theme."}
        </p>
      </div>
      <div className="theme-card">
        <ThemeToggler />
      </div>
    </div>
  );
};

export default ThemePage;
