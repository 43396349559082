import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { CHANGE_SELLER_PASSWORD } from "api/graphql/queries/changeSellerPasswordQuery"; // Import query
import { useAuth } from "providers/AuthProvider"; // Import the AuthContext to get customerId

const SecurityPage: React.FC = () => {
  const { t } = useTranslation();

  // Get customerId (entity_id) from context
  const { seller } = useAuth();
  const customerId = seller?.entity_id; // Access the entity_id (customerId)

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Trigger the GraphQL query for changing the password
  const { refetch, loading, error, data } = useQuery(CHANGE_SELLER_PASSWORD, {
    skip: true, // Don't auto-run the query; we will trigger it manually
  });

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Basic form validation
    if (newPassword !== confirmNewPassword) {
      setErrorMessage(t("passwordMismatch"));
      return;
    }

    if (!customerId) {
      setErrorMessage(t("customerIdMissing"));
      return;
    }

    try {
      const authToken = localStorage.getItem("ala");

      // Trigger the change password query
      const { data } = await refetch({
        customerId,
        currentPassword,
        newPassword,
        context: {
          headers: {
            Authorization: `Bearer ${authToken}`, // Ensure the token is sent
          },
        },
      });

      if (data?.changeSellerPassword?.success) {
        setSuccessMessage(t("passwordChangedSuccessfully"));
        setErrorMessage(""); // Clear error message if successful
      } else {
        setErrorMessage(
          data?.changeSellerPassword?.message || t("passwordChangeFailed")
        );
        setSuccessMessage("");
      }
    } catch (error) {
      setErrorMessage(t("errorChangingPassword"));
      setSuccessMessage("");
    }
  };

  return (
    <div className="d-flex flex-column">
      <div className="content-wrapper d-flex flex-column align-items-center justify-content-start flex-grow-1">
        <div className="container-small-fluid">
          <h2 className="mt-2 mb-4 text-center">{t("updatePasswordTitle")}</h2>

          {/* Display success or error messages */}
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}

          <Form
            className="shadow-lg p-4 w-80 rounded container-fluid col-sm-12 col-md-8 col-lg-6"
            onSubmit={handleSubmit}
          >
            <Form.Group controlId="currentPassword">
              <Form.Label>{t("currentPassword")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("enterCurrentPassword")}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="newPassword" className="mt-3">
              <Form.Label>{t("newPassword")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("enterNewPassword")}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="confirmNewPassword" className="mt-3">
              <Form.Label>{t("confirmNewPassword")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("confirmNewPassword")}
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </Form.Group>

            <Button
              variant="primary"
              className="mt-4 w-100"
              type="submit"
              disabled={loading}
            >
              {loading ? t("changingPassword") : t("updatePassword")}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SecurityPage;
