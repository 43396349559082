import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import classNames from "classnames";

interface FooterSigninFooterProps {
  className?: string;
}

const FooterSignin = ({ className }: FooterSigninFooterProps) => {
  const { t, i18n } = useTranslation();

  const languageOptions = [
    {
      value: "en",
      label: (
        <span>
          <img
            src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
            alt="English"
            className="language-flag"
          />
          English
        </span>
      ),
    },
    {
      value: "fr",
      label: (
        <span>
          <img
            src="https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"
            alt="French"
            className="language-flag"
          />
          Français
        </span>
      ),
    },
    {
      value: "ar",
      label: (
        <span>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg"
            alt="Arabic"
            className="language-flag"
          />
          العربية
        </span>
      ),
    },
  ];

  const handleLanguageChange = (selectedOption: any) => {
    i18n.changeLanguage(selectedOption.value);
  };

  return (
    <footer className={classNames(className, "creative-footer")}>
      <div className="container">
        <Row className="align-items-center">
          {/* Left Section: Brand and Rights */}
          <Col
            xs={12}
            md="6"
            className={classNames(
              "text-center",
              "text-md-start",
              "mb-3",
              "mb-md-0"
            )}
          >
            <p className="mb-0 footer-brand">
              &copy; {new Date().getFullYear()}{" "}
              <a
                href="https://www.wamia.tn"
                target="_blank"
                rel="noreferrer"
                className="footer-link"
              >
                Wamia
              </a>
              . {t("allRightsReserved")}
            </p>
          </Col>

          {/* Right Section: Language Selector */}
          <Col
            xs={12}
            md="6"
            className={classNames("text-center", "text-md-end")}
          >
            <div className="language-selector">
              <Select
                options={languageOptions}
                onChange={handleLanguageChange}
                defaultValue={languageOptions.find(
                  (option) => option.value === i18n.language
                )}
                isSearchable={false}
                components={{ IndicatorSeparator: () => null }}
                classNamePrefix="language-select"
              />
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
};
export default FooterSignin;
