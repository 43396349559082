import React, { useEffect } from "react";
import { useAppContext } from "providers/AppProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon, faDesktop } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface ThemeTogglerProps {
  className?: string;
}

const ThemeToggler: React.FC<ThemeTogglerProps> = ({ className }) => {
  const {
    config: { theme },
    setConfig,
  } = useAppContext();

  const { t } = useTranslation();

  return (
    <div className={`theme-toggler ${className}`}>
      <div
        className={`theme-option ${theme === "light" ? "active" : ""}`}
        onClick={() => setConfig({ theme: "light" })}
      >
        <FontAwesomeIcon icon={faSun} />
        <span>{t("themeLight")}</span>
      </div>
      <div
        className={`theme-option ${theme === "dark" ? "active" : ""}`}
        onClick={() => setConfig({ theme: "dark" })}
      >
        <FontAwesomeIcon icon={faMoon} />
        <span>{t("themeDark")}</span>
      </div>
      <div
        className={`theme-option ${theme === "auto" ? "active" : ""}`}
        onClick={() => setConfig({ theme: "auto" })}
      >
        <FontAwesomeIcon icon={faDesktop} />
        <span>{t("themeSystem")}</span>
      </div>
    </div>
  );
};

export default ThemeToggler;
