import SignUpForm from "components/modules/auth/SignUpForm";
import AuthSimpleLayout from "layouts/AuthSimpleLayout";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import FooterSignin from "components/footers/Footer_signin";

const SignUp = () => {
  return (
    <AuthSimpleLayout>
      <div className="signup-container">
        <SignUpForm layout="simple" />
        <FooterSignin />
      </div>
    </AuthSimpleLayout>
  );
};

export default SignUp;
