// src/api/graphql/queries/sellerRegisterQuery.ts

import { gql } from "@apollo/client";

export const SELLER_REGISTER = gql`
  query SellerRegister(
    $email: String!
    $password: String!
    $shopTitle: String!
    $contactNumber: String!
    $description: String!
    $billingAddress: BillingAddressInput!
  ) {
    sellerRegister(
      email: $email
      password: $password
      shopTitle: $shopTitle
      contactNumber: $contactNumber
      description: $description
      billingAddress: $billingAddress
    ) {
      success
      seller {
        id
        email
        shop_title
        contact_number
        description
        shop_url
      }
      token
      message
    }
  }
`;

export interface BillingAddressInput {
  firstname: string;
  lastname: string;
  company: string;
  street: string[];
  city: string;
  postcode: string;
  country_id: string;
  region: {
    region: string;
    region_id: number;
    region_code: string;
  };
  vat_id: string;
}
