// src/api/graphql/queries/sellerLogoutQuery.ts

import { gql } from "@apollo/client";

export const SELLER_LOGOUT = gql`
  query SellerLogout {
    sellerLogout {
      success
      message
    }
  }
`;
