import React, { useState, useCallback, useEffect, useRef } from "react";
import Button from "components/base/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { Col, Form, Row, Toast, ToastContainer } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import Dropzone from "components/custom/CustomDropzone";
import TinymceEditor from "components/base/TinymceEditor";
import VariantFormCard from "components/cards/VariantFormCard";
import ProductVariantsTable from "components/custom/ProductVariantsTable";
import CustomCascader from "components/custom/CustomCascader";
import ProductTabs from "components/tabs/ProductTabs";

import api from "api/api";
import { useTranslation } from "react-i18next";
import productAttributes from "config/productAttributes";

import { FileAttachment } from "components/custom/types";
import { debounce } from "picmo";

interface Option {
  option_id: string;
  attribute_id?: string;
  value?: string;
  swatch?: string;
}

interface OptionsGetter {
  method: string;
  url: string;
  param: {
    key: string;
    value: string;
  };
  token: string;
  minlength: number;
  debounceTime: number;
}

interface Attribute {
  attribute_id: string;
  attribute_code: string;
  frontend_label: string;
  is_required: string;
  input_type: string;
  options_getter?: OptionsGetter;
  options?: Option[];
}

interface AttributeGroup {
  name: string;
  icon?: string;
  display?: string;
  attributes: Attribute[];
}

interface CategorySelection {
  level1: { id: string; parent_id: string; name: string } | null;
  level2: { id: string; parent_id: string; name: string } | null;
  level3: { id: string; parent_id: string; name: string } | null;
}

const LOCAL_STORAGE_KEY = "addProductFormValues";

const AddProduct: React.FC = () => {
  // 1) If attaching to <h1>, we use HTMLHeadingElement here
  const tableHeaderRef = useRef<HTMLTableSectionElement | null>(null);

  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<{ [key: string]: any }>(() => {
    const savedValues = localStorage.getItem(LOCAL_STORAGE_KEY);
    return savedValues ? JSON.parse(savedValues) : {};
  });

  const [characteristicsAttributes, setCharacteristicsAttributes] = useState<
    Attribute[]
  >([]);
  const [dynamicOptions, setDynamicOptions] = useState<{
    [key: string]: Option[];
  }>({});
  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false);

  const [initialCategorySelection, setInitialCategorySelection] =
    useState<CategorySelection>({
      level1: null,
      level2: null,
      level3: null,
    });

  // Variation states
  const [variationAttributes, setVariationAttributes] = useState<Attribute[]>(
    []
  );
  const [isLoadingAttributes, setIsLoadingAttributes] =
    useState<boolean>(false);
  const [generatedVariants, setGeneratedVariants] = useState<any[]>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<Attribute[]>([]);

  // Toast states
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState<"success" | "danger">(
    "danger"
  );
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  // Debounced input change for fetching options
  const handleInputChange = useCallback(
    debounce(async (query: string, attribute: Attribute) => {
      if (attribute.options_getter) {
        setIsLoadingOptions(true);
        try {
          const response = await api.get(
            `${attribute.options_getter.url}?${attribute.options_getter.param.key}=${query}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              },
            }
          );
          setDynamicOptions((prev) => ({
            ...prev,
            [attribute.attribute_code]: response.data,
          }));
        } catch (error) {
          console.error("Error fetching options:", error);
        } finally {
          setIsLoadingOptions(false);
        }
      }
    }, 500),
    []
  );

  // Fetch categories
  const fetchCategories = async (parentId: string | null) => {
    try {
      const rootCategoryId = parentId || "2";
      const response = await api.get(
        `/categories?rootCategoryId=${rootCategoryId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching categories:", error);
      return [];
    }
  };

  // Fetch attributes for selected category
  const fetchFeatures = async (categoryId: string) => {
    try {
      setIsLoadingAttributes(true);
      const response = await api.get(`/getfeatures?categoryId=${categoryId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setCharacteristicsAttributes(response.data);

      // Variation attributes that appear in these features
      const existingVariationAttributes =
        productAttributes.VariationAttributes.filter((va) =>
          response.data.some(
            (fetchedAttr: Attribute) =>
              fetchedAttr.attribute_code === va.attribute_code
          )
        ).map((va) => {
          const matchedAttr = response.data.find(
            (fetchedAttr: Attribute) =>
              fetchedAttr.attribute_code === va.attribute_code
          );
          if (matchedAttr) {
            return {
              ...va,
              options: matchedAttr.options,
            };
          }
          return va;
        });

      setVariationAttributes(existingVariationAttributes);
      setIsLoadingAttributes(false);
    } catch (error) {
      console.error("Error fetching features:", error);
      setIsLoadingAttributes(false);
    }
  };

  const handleCategorySelection = (selectedCategory: CategorySelection) => {
    handleChange("category", selectedCategory);
    if (selectedCategory.level3) {
      fetchFeatures(selectedCategory.level3.id);
    }
  };

  // Clear form
  const handleClear = () => {
    setFormValues({});
    localStorage.removeItem(LOCAL_STORAGE_KEY);

    setGeneratedVariants([]);
    setSelectedAttributes([]);
    setInitialCategorySelection({ level1: null, level2: null, level3: null });

    setFormValues((prev) => ({ ...prev, gallery: [], brand: [] }));
  };

  // Save draft
  const handleSaveDraft = async () => {
    setLoading(true);
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(formValues));
    } catch (error) {
      console.error("Error saving draft:", error);
    } finally {
      setLoading(false);
    }
  };

  // Validate & publish
  const validateForm = () => {
    const invalid: string[] = [];

    // Common attributes
    productAttributes.CommonAttributes.AttributeGroups.forEach((group) => {
      if (typeof group.attributes !== "string") {
        const excludedAttributes = ["category_ids", "sku"];
        group.attributes.forEach((attr) => {
          if (
            attr.is_required === "1" &&
            !formValues[attr.attribute_code] &&
            !excludedAttributes.includes(attr.attribute_code)
          ) {
            invalid.push(attr.attribute_code);
          }
        });
      }
    });

    // Tabs
    allInTabGroups.forEach((group) => {
      if (typeof group.attributes !== "string") {
        group.attributes.forEach((attr) => {
          if (attr.is_required === "1" && !formValues[attr.attribute_code]) {
            invalid.push(attr.attribute_code);
          }
        });
      }
    });
    return invalid;
  };

  const handlePublishProduct = async () => {
    const missingFields = validateForm();
    if (missingFields.length > 0) {
      setToastMessage("Please fill out all required fields.");
      setToastVariant("danger");
      setShowToast(true);
      setInvalidFields(missingFields);
      return;
    }
    setInvalidFields([]);

    setLoading(true);

    const payload = { ...formValues };
    delete payload.selectedAttributeOptions_0;
    delete payload.selectedAttributeOptions_1;
    delete payload.variantAttribute_0;
    delete payload.variantAttribute_1;
    if (!payload.gallery) payload.gallery = [];

    try {
      const response = await api.post("/product/create", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      setToastMessage("Product published successfully.");
      setToastVariant("success");
      setShowToast(true);

      console.log("Success:", response.data);
      handleClear();
    } catch (error) {
      console.error("Error publishing product:", error);
      setToastMessage("Error publishing product.");
      setToastVariant("danger");
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  // Generic handle change
  const handleChange = (attribute_code: string, value: any) => {
    setFormValues((prev) => ({
      ...prev,
      [attribute_code]: value,
    }));
  };

  // Called when we click "create variants" in `VariantFormCard`
  const handleCreateVariants = (
    variants: any[],
    selectedAttrs: Attribute[]
  ) => {
    setGeneratedVariants(variants);
    setSelectedAttributes(selectedAttrs);

    setFormValues((prev) => ({
      ...prev,
      variantsData: variants,
      selectedAttributes: selectedAttrs,
    }));

    // SCROLL to the <h1> in ProductVariantsTable after rendering
    setTimeout(() => {
      if (tableHeaderRef.current) {
        tableHeaderRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }, 200);
  };

  // Removing a single variant from the form card
  const handleRemoveVariant = (index: number) => {
    const updated = [...generatedVariants];
    updated.splice(index, 1);
    setGeneratedVariants(updated);

    const updatedForm = { ...formValues };
    delete updatedForm[`selectedAttributeOptions_${index}`];
    delete updatedForm[`variantAttribute_${index}`];

    if (index === 0 && formValues.variantAttribute_1) {
      updatedForm.variantAttribute_0 = formValues.variantAttribute_1;
      delete updatedForm.variantAttribute_1;
      updatedForm.selectedAttributeOptions_0 =
        formValues.selectedAttributeOptions_1;
      delete updatedForm.selectedAttributeOptions_1;
    } else if (index === 1) {
      delete updatedForm.variantAttribute_1;
      delete updatedForm.selectedAttributeOptions_1;
    }
    setFormValues({ ...updatedForm, variantsData: updated });
  };

  // Utility
  const createCompositeKey = (variant: any, attributes: Attribute[]) => {
    return attributes
      .map((attr) => variant[attr.attribute_code]?.option_id || "")
      .join("-");
  };

  // For removing a row in the final table
  const handleDeleteVariant = (compositeKey: string) => {
    setGeneratedVariants((prev) =>
      prev.filter((variant) => {
        const variantKey = createCompositeKey(variant, selectedAttributes);
        return variantKey !== compositeKey;
      })
    );
  };

  // For updating the entire variant array
  const handleUpdateVariant = (updatedVariants: any[]) => {
    setGeneratedVariants(updatedVariants);
    setFormValues((prev) => ({
      ...prev,
      variantsData: updatedVariants,
    }));
  };

  // For updating an image of a certain variant
  const handleUpdateVariantImage = (
    imagedAttributeCode: string,
    imagedAttributeValue: string,
    newImage: FileAttachment
  ) => {
    setGeneratedVariants((prev) => {
      const updated = prev.map((variant) => {
        if (variant[imagedAttributeCode]?.value === imagedAttributeValue) {
          return { ...variant, image: newImage };
        }
        return variant;
      });
      setFormValues((p) => ({ ...p, variantsData: updated }));
      return updated;
    });
  };

  // Handling file uploads
  const handleFileDrop = async (files: any[]) => {
    const updatedGallery = [...(formValues.gallery || [])];
    updatedGallery.push(...files);
    setFormValues((prev) => ({ ...prev, gallery: updatedGallery }));
  };

  const handleFileRemove = (fileName: string) => {
    setFormValues((prev) => {
      const updated =
        prev.gallery?.filter((f: FileAttachment) => f.name !== fileName) || [];
      return { ...prev, gallery: updated };
    });
  };

  const handleFileUpdate = (
    index: number,
    updatedData: Partial<FileAttachment>
  ) => {
    setFormValues((prev) => {
      const updated = [...(prev.gallery || [])];
      if (updatedData.roles) {
        // clear roles from all other files if they are set
        Object.keys(updatedData.roles).forEach((role) => {
          if (updatedData.roles![role]) {
            updated.forEach((file, i) => {
              if (i !== index && file.roles?.[role]) {
                file.roles[role] = false;
              }
            });
          }
        });
      }
      updated[index] = { ...updated[index], ...updatedData };
      return { ...prev, gallery: updated };
    });
  };

  const handleFileIndexChange = (fromIndex: number, toIndex: number) => {
    setFormValues((prev) => {
      const updated = [...(prev.gallery || [])];
      const [movedFile] = updated.splice(fromIndex, 1);
      updated.splice(toIndex, 0, movedFile);
      return { ...prev, gallery: updated };
    });
  };

  // On mount, restore from localStorage
  useEffect(() => {
    const saved = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (saved) {
      const parsed = JSON.parse(saved);
      setFormValues(parsed);

      if (parsed.category) {
        setInitialCategorySelection(parsed.category as CategorySelection);
      }
      if (parsed.variantsData) {
        setGeneratedVariants(parsed.variantsData);
      }
      if (parsed.selectedAttributes) {
        setSelectedAttributes(parsed.selectedAttributes);
      }
    }
  }, []);

  // Example: grouping attributes for in-tab vs. out-of-tab
  const commonAttributeGroups =
    productAttributes.CommonAttributes.AttributeGroups.filter(
      (g) => !g.display || g.display !== "inTab"
    );
  const inTabAttributeGroups =
    productAttributes.CommonAttributes.AttributeGroups.filter(
      (g) => g.display && g.display === "inTab"
    );
  const allInTabGroups: AttributeGroup[] = [...inTabAttributeGroups];

  const characteristicsGroup = allInTabGroups.find(
    (g) => g.name === "Caractéristiques"
  );
  if (characteristicsGroup) {
    if (Array.isArray(characteristicsGroup.attributes)) {
      characteristicsGroup.attributes = characteristicsAttributes;
    }
  } else {
    allInTabGroups.push({
      name: "Caractéristiques",
      icon: "sliders",
      attributes: characteristicsAttributes,
    });
  }

  return (
    <div>
      {/* Toast for success/errors */}
      <ToastContainer
        position="top-end"
        className="p-3"
        style={{ position: "fixed", top: "1rem", right: "1rem", zIndex: 9999 }}
      >
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          bg={toastVariant === "success" ? "success" : "danger"}
          delay={3000}
          autohide
        >
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <form className="mb-12">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2
              className="mb-2"
              style={{ textAlign: isArabic ? "right" : "left" }}
            >
              {t("addProductTitle")}
            </h2>
            <h5
              className="text-body-tertiary fw-semibold"
              style={{ textAlign: isArabic ? "right" : "left" }}
            >
              {t("addProductSubtitle")}
            </h5>
          </div>
          <div className="d-flex flex-wrap gap-2">
            <Button
              variant="phoenix-secondary"
              type="button"
              onClick={handleClear}
            >
              {t("clear")}
            </Button>
            <Button
              variant="phoenix-primary"
              type="button"
              onClick={handleSaveDraft}
              style={{ opacity: loading ? 0.5 : 1 }}
            >
              <FontAwesomeIcon
                icon={faFloppyDisk}
                style={{ marginRight: "8px" }}
              />
              {t("saveDraft")}
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={handlePublishProduct}
            >
              {t("publishProduct")}
            </Button>
          </div>
        </div>

        {/* Left side: common attributes + tabs */}
        <Row className="g-5">
          <Col xs={12} xl={8}>
            {commonAttributeGroups.map((group) => {
              if (typeof group.attributes === "string") return null;
              return group.attributes.map((attribute: Attribute) => {
                const inputValue = formValues[attribute.attribute_code] || "";
                const savedOptions = Array.isArray(
                  formValues[attribute.attribute_code]
                )
                  ? formValues[attribute.attribute_code]
                  : [];
                const combinedOptions = [
                  { option_id: "0", value: "", swatch: "" },
                  ...(dynamicOptions[attribute.attribute_code] ??
                    attribute.options ??
                    []),
                ];

                switch (attribute.input_type) {
                  case "text":
                  case "price":
                  case "weight":
                    return (
                      <Form.Group key={attribute.attribute_id} className="mb-3">
                        <Form.Label
                          style={{ textAlign: isArabic ? "right" : "left" }}
                        >
                          {t(attribute.frontend_label)}{" "}
                          {attribute.is_required === "1" && (
                            <span className="text-danger">*</span>
                          )}
                        </Form.Label>
                        <Form.Control
                          type={
                            attribute.input_type === "text" ? "text" : "number"
                          }
                          placeholder={t(attribute.frontend_label)}
                          required={attribute.is_required === "1"}
                          value={inputValue}
                          onChange={(e) =>
                            handleChange(
                              attribute.attribute_code,
                              e.target.value
                            )
                          }
                          className={
                            invalidFields.includes(attribute.attribute_code)
                              ? "is-invalid"
                              : ""
                          }
                        />
                      </Form.Group>
                    );
                  case "textarea":
                    return (
                      <Form.Group key={attribute.attribute_id} className="mb-3">
                        <Form.Label
                          style={{ textAlign: isArabic ? "right" : "left" }}
                        >
                          {t(attribute.frontend_label)}{" "}
                          {attribute.is_required === "1" && (
                            <span className="text-danger">*</span>
                          )}
                        </Form.Label>
                        <TinymceEditor
                          options={{
                            height: "15rem",
                            placeholder: t(attribute.frontend_label),
                          }}
                          value={inputValue}
                          onChange={(content) =>
                            handleChange(attribute.attribute_code, content)
                          }
                        />
                      </Form.Group>
                    );
                  case "gallery":
                    return (
                      <Form.Group key={attribute.attribute_id} className="mb-3">
                        <Form.Label
                          style={{ textAlign: isArabic ? "right" : "left" }}
                        >
                          {t(attribute.frontend_label)}{" "}
                          {attribute.is_required === "1" && (
                            <span className="text-danger">*</span>
                          )}
                        </Form.Label>
                        <Dropzone
                          accept={{
                            "image/*": [".png", ".gif", ".jpeg", ".jpg"],
                          }}
                          onDrop={handleFileDrop}
                          defaultFiles={(formValues.gallery || []).map(
                            (f: FileAttachment) => ({
                              name: f.name,
                              preview: f.preview,
                              size: f.size,
                              resolution: f.resolution,
                              altText: f.altText,
                              hideFromProductPage: f.hideFromProductPage,
                              roles: f.roles,
                            })
                          )}
                          onFileRemove={handleFileRemove}
                          onFileUpdate={handleFileUpdate}
                          onFileIndexChange={handleFileIndexChange}
                        />
                      </Form.Group>
                    );
                  case "cascader":
                    return (
                      <Form.Group key={attribute.attribute_id} className="mb-5">
                        <Form.Label
                          style={{ textAlign: isArabic ? "right" : "left" }}
                        >
                          {t(attribute.frontend_label)}{" "}
                          {attribute.is_required === "1" && (
                            <span className="text-danger">*</span>
                          )}
                        </Form.Label>
                        <CustomCascader
                          fetchCategories={(parentId) =>
                            fetchCategories(parentId)
                          }
                          onCategorySelected={handleCategorySelection}
                          initialSelection={initialCategorySelection}
                        />
                      </Form.Group>
                    );
                  case "select":
                  case "multiselect":
                    return (
                      <Form.Group key={attribute.attribute_id} className="mb-3">
                        <Form.Label
                          style={{ textAlign: isArabic ? "right" : "left" }}
                        >
                          {t(attribute.frontend_label)}{" "}
                          {attribute.is_required === "1" && (
                            <span className="text-danger">*</span>
                          )}
                        </Form.Label>
                        <Typeahead
                          id={attribute.attribute_id}
                          className={
                            invalidFields.includes(attribute.attribute_code)
                              ? "is-invalid"
                              : ""
                          }
                          labelKey="value"
                          multiple={attribute.input_type === "multiselect"}
                          options={combinedOptions}
                          isLoading={isLoadingOptions}
                          placeholder={t(attribute.frontend_label)}
                          onInputChange={(query) => {
                            if (attribute.options_getter) {
                              handleInputChange(query, attribute);
                            }
                          }}
                          // If we have savedOptions already, use them
                          selected={
                            Array.isArray(savedOptions) ? savedOptions : []
                          }
                          onChange={(selected) =>
                            handleChange(attribute.attribute_code, selected)
                          }
                          renderMenuItemChildren={(option) => {
                            const opt = option as Option;
                            return (
                              <div className="d-flex align-items-center">
                                {opt.swatch && (
                                  <div
                                    style={{
                                      backgroundColor: opt.swatch,
                                      width: "16px",
                                      height: "16px",
                                      marginRight: "8px",
                                      border: "1px solid #ccc",
                                    }}
                                  />
                                )}
                                <span>{t(opt.value || "")}</span>
                              </div>
                            );
                          }}
                        />
                      </Form.Group>
                    );
                  default:
                    return null;
                }
              });
            })}

            <ProductTabs
              AttributeGroups={allInTabGroups as unknown as AttributeGroup[]}
              formValues={formValues}
              handleChange={handleChange}
              invalidFields={invalidFields}
            />
          </Col>

          {/* Right side: Variation form card */}
          <Col xs={12} xl={4}>
            <Row className="g-2">
              <Col xs={12} xl={12}>
                <VariantFormCard
                  variationAttributes={variationAttributes}
                  handleChange={handleChange}
                  formValues={formValues}
                  onCreateVariants={handleCreateVariants}
                  onRemoveVariant={handleRemoveVariant}
                  isLoadingAttributes={isLoadingAttributes}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </form>

      {/* Table of generated variants */}
      <Row className="g-2">
        <Col xs={12} xl={12}>
          {generatedVariants.length > 0 && (
            <ProductVariantsTable
              // 2) Passing the same ref to the child
              tableHeaderRef={tableHeaderRef}
              variantsData={generatedVariants}
              selectedAttributes={selectedAttributes}
              onDeleteVariant={handleDeleteVariant}
              onUpdateVariant={handleUpdateVariant}
              formValues={formValues}
              onUpdateVariantImage={handleUpdateVariantImage}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AddProduct;
