import React, { useState, useEffect } from "react";
import ReactSelect from "components/base/ReactSelect";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CSSObjectWithLabel } from "react-select";
import { useTranslation } from "react-i18next";
import { Attribute, Option } from "config/productAttributes";

interface ProductVariantProps {
  className?: string;
  index: number;
  options: Attribute[];
  onRemove: () => void;
  handleChange: (attribute_code: string, value: any) => void; // Handle changes for ReactSelect
  formValues: { [key: string]: any };
  disableRemoveButton?: boolean;
}

const ProductVariant: React.FC<ProductVariantProps> = ({
  className,
  index,
  options,
  onRemove,
  handleChange,
  formValues,
  disableRemoveButton,
}) => {
  const { t } = useTranslation();

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [selectedReactSelectValues, setSelectedReactSelectValues] = useState<
    any[]
  >([]);
  const [selectedAttributeId, setSelectedAttributeId] = useState<
    string | undefined
  >();

  useEffect(() => {
    const storedAttribute = formValues[`variantAttribute_${index}`];
    if (storedAttribute) {
      setSelectedAttributeId(storedAttribute.attribute_id);
      const selectedAttribute = options.find(
        (option) => option.attribute_id === storedAttribute.attribute_id
      );
      if (selectedAttribute) {
        setSelectedOptions(selectedAttribute.options || []);
      }
    }

    const storedSelectedOptions =
      formValues[`selectedAttributeOptions_${index}`];
    if (storedSelectedOptions) {
      setSelectedReactSelectValues(
        storedSelectedOptions.map((opt: Option) => ({
          value: opt.value,
          option_id: opt.option_id,
          label: renderOption(opt),
        }))
      );
    }
  }, [formValues, index, options]);

  const customStyles = {
    control: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      height: "auto",
      alignItems: "flex-start",
      color: "var(--phoenix-body-color)",
    }),
    dropdownIndicator: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      display: "none",
    }),
    option: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      display: "flex",
      alignItems: "center",
    }),
    multiValue: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      display: "flex",
      alignItems: "center",
      color: "var(--phoenix-body-color)",
    }),
  };

  const handleAttributeSelect = (inputId: string, attributeId: string) => {
    const selectedAttribute = options.find(
      (option) => option.attribute_id === attributeId
    );
    if (selectedAttribute) {
      setSelectedOptions(selectedAttribute.options || []);
      setSelectedReactSelectValues([]);
      handleChange(inputId, selectedAttribute);
    } else {
      setSelectedOptions([]);
      setSelectedReactSelectValues([]);
    }
  };

  const renderOption = (opt: Option) => (
    <div className="d-flex align-items-center">
      {opt.swatch && (
        <div
          style={{
            backgroundColor: opt.swatch,
            width: "16px",
            height: "16px",
            marginRight: "8px",
            border: "1px solid #ccc",
          }}
        />
      )}
      <span>{opt.value}</span>
    </div>
  );

  return (
    <div className={className}>
      <div className="d-flex gap-2 mb-2">
        <h5 className="mb-0 text-body-highlight">{t("option")}</h5>
        <Link
          className={`fw-bold fs-9 ${disableRemoveButton ? "text-muted" : ""}`}
          to="#!"
          onClick={disableRemoveButton ? undefined : onRemove}
          style={{ pointerEvents: disableRemoveButton ? "none" : "auto" }}
        >
          {t("remove")}
        </Link>
      </div>
      <Form.Select
        className="mb-3"
        id={`variantAttribute_${index}`}
        value={selectedAttributeId}
        onChange={(e) => {
          handleAttributeSelect(`variantAttribute_${index}`, e.target.value);
          setSelectedAttributeId(e.target.value);
          setSelectedReactSelectValues([]);
          handleChange(`selectedAttributeOptions_${index}`, []);
        }}
      >
        <option value="">{t("selectAttributeOptions")}</option>
        {options.map((option) => (
          <option key={option.attribute_code} value={option.attribute_id}>
            {t(option.frontend_label.toLowerCase())}
          </option>
        ))}
      </Form.Select>
      <ReactSelect
        options={selectedOptions.map((opt) => ({
          value: opt.value,
          option_id: opt.option_id,
          swatch: opt.swatch,
          label: renderOption(opt),
        }))}
        value={selectedReactSelectValues}
        isMulti
        placeholder={t("selectOption")}
        styles={customStyles as any}
        components={{
          MultiValueLabel: ({ data }) => (
            <div className="d-flex align-items-center">
              {data.swatch && (
                <div
                  style={{
                    backgroundColor: data.swatch,
                    width: "16px",
                    height: "16px",
                    marginRight: "8px",
                    border: "1px solid #ccc",
                  }}
                />
              )}
              <span>{data.label}</span>
            </div>
          ),
        }}
        onChange={(selected: any) => {
          setSelectedReactSelectValues(selected);
          const selectedOptionsArray = selected.map((opt: any) => {
            const matchingOption = selectedOptions.find(
              (option) => option.option_id === opt.option_id
            );
            return {
              option_id: opt.option_id,
              value: matchingOption?.value || "",
              swatch: matchingOption?.swatch || "",
            };
          });
          handleChange(
            `selectedAttributeOptions_${index}`,
            selectedOptionsArray
          );
        }}
      />
    </div>
  );
};

export default ProductVariant;
