import { gql } from "@apollo/client";

export const CHANGE_SELLER_PASSWORD = gql`
  query ChangeSellerPassword(
    $customerId: Int!
    $currentPassword: String!
    $newPassword: String!
  ) {
    changeSellerPassword(
      customerId: $customerId
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      success
      message
    }
  }
`;
