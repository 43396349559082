import AppProvider from "providers/AppProvider";
import React from "react";
import ReactDOM from "react-dom/client";
import BreakpointsProvider from "providers/BreakpointsProvider";
import SettingsPanelProvider from "providers/SettingsPanelProvider";
import { RouterProvider } from "react-router-dom";
import { router } from "Routes";
import ChatWidgetProvider from "providers/ChatWidgetProvider";
import { AuthProvider } from "providers/AuthProvider";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { ApolloProvider } from "@apollo/client";
import client from "api/client";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <I18nextProvider i18n={i18n}>
        <AppProvider>
          <SettingsPanelProvider>
            <ChatWidgetProvider>
              <BreakpointsProvider>
                <AuthProvider>
                  <RouterProvider router={router} />
                </AuthProvider>
              </BreakpointsProvider>
            </ChatWidgetProvider>
          </SettingsPanelProvider>
        </AppProvider>
      </I18nextProvider>
    </ApolloProvider>
  </React.StrictMode>
);
