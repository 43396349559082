import React, { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import { useAppContext } from "providers/AppProvider";
import classNames from "classnames";
import NavbarBrand from "components/navbars/nav-items/NavbarBrand";
import NavItems from "components/navbars/nav-items/NavItems";
import NavItemsSlim from "components/navbars/nav-items/NavItemsSlim";
import { useQuery } from "@apollo/client";
import { GET_SELLER_PROFILE } from "api/graphql/queries/getSellerProfileQuery";

const NavbarTopDefault = () => {
  const {
    config: { navbarTopShape, navbarTopAppearance },
  } = useAppContext();

  // const { data, loading, error } = useQuery(GET_SELLER_PROFILE, {
  //   context: {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  //     },
  //   },
  // });

  // useEffect(() => {
  //   if (data && data.getSellerProfile?.success) {
  //     localStorage.setItem(
  //       "seller",
  //       JSON.stringify(data.getSellerProfile.data)
  //     );
  //   } else if (error) {
  //     console.error("Error fetching seller profile:", error);
  //   }
  // }, [data, error]); // Re-run effect when data or error changes

  return (
    <Navbar
      className={classNames("navbar-top fixed-top", {
        "navbar-slim": navbarTopShape === "slim",
      })}
      expand
      variant=""
      data-navbar-appearance={navbarTopAppearance === "darker" ? "darker" : ""}
    >
      <div className="navbar-collapse justify-content-between">
        <NavbarBrand />
        {navbarTopShape === "default" ? <NavItems /> : <NavItemsSlim />}
      </div>
    </Navbar>
  );
};

export default NavbarTopDefault;
