// src/api/client.ts

import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context"; // To dynamically set Authorization header

// Create an HTTP link for your GraphQL API
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_MAGENTO_GRAPHQL_URL, // Your GraphQL endpoint URL
});

// Use setContext to dynamically get the latest token for every request
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(""); // Get the latest token from localStorage
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Create Apollo Client instance
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(), // Cache to manage data
});

export default client;
