import SignInForm from "components/modules/auth/SignInForm";
import AuthSimpleLayout from "layouts/AuthSimpleLayout";
import FooterSignin from "components/footers/Footer_signin";

const SignIn = () => {
  return (
    <AuthSimpleLayout>
      <div className="signin-container">
        <SignInForm layout="simple" />
        <FooterSignin />
      </div>
    </AuthSimpleLayout>
  );
};

export default SignIn;
