import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useQuery } from "@apollo/client"; // Corrected: useQuery for both login and logout
import { LOGIN_QUERY } from "api/graphql/queries/loginQuery"; // Login query
import { SELLER_LOGOUT } from "api/graphql/queries/sellerLogoutQuery"; // Logout query

// Seller interface remains the same
interface Seller {
  entity_id: string;
  name: string;
  email: string;
  is_seller: string;
  shop_url: string;
  shop_title: string;
  logo_pic: string;
  company_locality: string;
  contact_number: string;
  billing_full: string;
  billing_firstname: string;
  billing_lastname: string;
  billing_telephone: string;
  billing_postcode: string;
  billing_country_id: string;
  billing_region: string;
  billing_region_id: string;
  billing_street: string;
  billing_city: string;
  billing_company: string;
  [key: string]: any;
}

interface AuthContextProps {
  isAuthenticated: boolean;
  seller: Seller | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  setSeller: React.Dispatch<React.SetStateAction<Seller | null>>;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [seller, setSeller] = useState<Seller | null>(null);

  // Use useQuery for login details when needed
  const {
    data: loginData,
    refetch: refetchLogin,
    loading: loginLoading,
    error: loginError,
  } = useQuery(LOGIN_QUERY, {
    variables: { username: "", password: "" }, // Default values, will be overridden later
    skip: true, // Skip the query until login is triggered
  });

  // Use useQuery for seller logout
  const {
    data: logoutData,
    refetch: refetchLogout,
    loading: logoutLoading,
    error: logoutError,
  } = useQuery(SELLER_LOGOUT, {
    skip: true, // Skip the query until logout is triggered
  });

  // Set token in the header and authentication state from localStorage on page load
  useEffect(() => {
    const token = localStorage.getItem("authToken_wamia");
    const storedSeller = localStorage.getItem("seller_wamia");

    if (token && storedSeller) {
      setSeller(JSON.parse(storedSeller));
      setIsAuthenticated(true); // Update the state to reflect the logged-in status
    } else {
      setIsAuthenticated(false); // Set the state to logged-out if no data exists
    }
  }, []);

  // Login function
  const login = async (email: string, password: string) => {
    try {
      const { data } = await refetchLogin({
        username: email,
        password,
      });

      const { seller, token, success } = data.sellerLogin;

      if (success) {
        localStorage.setItem("authToken_wamia", token);
        localStorage.setItem("seller_wamia", JSON.stringify(seller));

        setSeller(seller);
        setIsAuthenticated(true); // Set as authenticated on successful login
      } else {
        throw new Error("Login failed");
      }
    } catch (error: any) {
      console.error("Login error:", error);
      throw new Error(error.message); // Handle login error
    }
  };

  // Logout function
  const logout = async () => {
    try {
      const token = localStorage.getItem("authToken_wamia");

      if (!token) {
        throw new Error("No token found in localStorage.");
      }

      // Send the logout request with the Authorization header
      const { data } = await refetchLogout({
        context: {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token explicitly here
          },
        },
      });

      if (data?.sellerLogout?.success) {
        localStorage.removeItem("authToken_wamia");
        localStorage.removeItem("seller_wamia");

        setSeller(null);
        setIsAuthenticated(false);

        window.location.href = "/pages/authentication/simple/sign-in"; // Redirect to login
      } else {
        console.error("Logout failed:", data?.sellerLogout?.message);
      }
    } catch (error) {
      console.error("Logout failed:", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        seller,
        login,
        logout,
        setSeller,
        setIsAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
