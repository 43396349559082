// src/api/graphql/queries/loginQuery.ts

import { gql } from "@apollo/client";

export const LOGIN_QUERY = gql`
  query SellerLogin($username: String!, $password: String!) {
    sellerLogin(username: $username, password: $password) {
      success
      token
      seller {
        entity_id
        is_seller
        seller_id
        payment_source
        twitter_id
        facebook_id
        gplus_id
        youtube_id
        vimeo_id
        instagram_id
        pinterest_id
        moleskine_id
        tw_active
        fb_active
        gplus_active
        youtube_active
        vimeo_active
        instagram_active
        pinterest_active
        moleskine_active
        others_info
        banner_pic
        shop_url
        shop_title
        logo_pic
        company_locality
        country_pic
        company_description
        meta_keyword
        meta_description
        background_width
        store_id
        contact_number
        return_policy
        shipping_policy
        created_at
        updated_at
        admin_notification
        privacy_policy
        allowed_categories
        sub_account_permission
        seller_affilate_status
        is_seller_affilate_request
        name
        email
        group_id
        website_id
        confirmation
        created_in
        dob
        gender
        taxvat
        lock_expires
        customer_private_group
        shipping_full
        billing_full
        billing_firstname
        billing_lastname
        billing_telephone
        billing_postcode
        billing_country_id
        billing_region
        billing_region_id
        billing_street
        billing_city
        billing_fax
        billing_vat_id
        billing_company
      }
      message
    }
  }
`;
